<template>
  <div
    class="latestNews"
    :id="id"
    :style="{ backgroundColor: data.background_color }"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6 col-xl-5 offset-xl-1">
          <div class="latestNews__imageWrap">
            <img
              :src="data.image['post-temp']"
              :data-src="data.image.full"
              class="latestNews__image lazyload"
              alt="Image alt"
            />
          </div>
        </div>
        <div
          class="col-12 col-lg-5 col-xl-4 offset-xl-1 order-lg-first latestNews__content"
        >
          <div>
            <div class="latestNews__content__label">
              <span>Case</span>
              <span class="latestNews__content__label__date"
                >/&nbsp;&nbsp;Jensens</span
              >
            </div>
            <h2 class="latestNews__content__h2">{{ data.title }}</h2>
            <p>{{ data.excerpt }}</p>
            <a :href="data.select_page.link" class="button"
              ><span>read more</span></a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 		<?php if($gif): ?>
			<div class="flyingBlock">
				<div class="flyingBlock__img">
					<img data-src="<?php echo $gif['url']; ?>" class='lazyload' alt='STEP'>
				</div>
			</div>
		<?php elseif($video): ?>
			<div class="flyingBlock">
				<video autoplay loop muted>
					<source src="<?php echo $video; ?>" type="video/mp4">
				</video>
			</div>
		<?php endif; ?> -->
  </div>
</template>

<script>
import uniqueId from "lodash-es/uniqueId";
import TypeIt from "typeit";
import gsap from "gsap";

export default {
  name: "Hero",
  props: {
    data: Object
  },
  mounted() {
    this.animation_latestNews();
  },
  methods: {
    animation_latestNews() {
      // if(ie) return;

      let news_tl = gsap.timeline({ ease: "power2.out" });

      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".latestNews",
            start: "top center"
          }
        })
        .from(
          ".latestNews__image",
          {
            duration: 0.3,
            ease: "power2.out",
            opacity: 0,
            yPercent: 50
          },
          0
        );

      new TypeIt(
        document.querySelectorAll(".latestNews__content__label span")[0],
        {
          speed: 20,
          loop: false,
          waitUntilVisible: true,
          cursorChar: '<i class="cursor--minimal cursor--black cursor"></i>',
          afterComplete: async (step, instance) => {
            instance.destroy();
            gsap.set(".latestNews__content__label__date", { autoAlpha: 1 });

            news_tl.to(".latestNews__content p, .latestNews__content .button", {
              autoAlpha: 1,
              y: 0,
              duration: 0.4,
              delay: 0.7
            });

            new TypeIt(
              document.querySelector(".latestNews__content__label__date"),
              {
                speed: 18,
                loop: false,
                waitUntilVisible: true,
                cursorChar:
                  '<i class="cursor--minimal cursor--black cursor"></i>',
                afterComplete: async (step, instance) => {
                  instance.destroy();
                  gsap.set(".latestNews__content__h2", { autoAlpha: 1 });

                  new TypeIt(".latestNews__content__h2", {
                    speed: 20,
                    loop: false,
                    cursorChar:
                      '<i class="cursor--medium cursor--black cursor"></i>',
                    afterComplete: async (step, instance) => {
                      setTimeout(instance.destroy, 2000);
                    }
                  }).go();
                }
              }
            ).go();
          }
        }
      ).go();
    }
  },
  computed: {
    id() {
      return uniqueId("id");
    }
  }
};
</script>
