<template>
  <div
    class="caseText"
    :id="id"
    :style="{ backgroundColor: data.background_color }"
  >
    <div class="container">
      <div v-if="data.title_left" class="row">
        <div class="col-12 offset-lg-1 col-lg-2">
          <h6>{{ data.title_left }}</h6>
        </div>
        <div class="col-12 col-lg-7">
          <h6>{{ data.title_main }}</h6>
          <p>{{ data.text }}</p>
        </div>
      </div>
      <div v-else class="row">
        <h4>{{ data.title_main }}</h4>
        <p>{{ data.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import uniqueId from "lodash-es/uniqueId";

export default {
  name: "Hero",
  props: {
    data: Object
  },
  methods: {},
  computed: {
    id() {
      return uniqueId("id");
    }
  }
};
</script>
