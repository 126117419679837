<template v-if="data.repeater_video_gallery_.length > 0">
  <div>
    <div class="hero">
      <div
        class="hero__cover"
        v-if="data.repeater_video_gallery_.repeater_video_gallery_0.video"
      >
        <video width="100%" height="100%" id="hero__video" muted autoplay>
          <source
            :src="data.repeater_video_gallery_.repeater_video_gallery_0.video"
            id="hero__video__main__src"
            type="video/mp4"
          />
        </video>
        <video
          width="100%"
          height="100%"
          id="hero__video--hover"
          muted
          autoplay
          loop
        >
          <source src="" type="video/mp4" id="hero__video__src" />
        </video>
        <div
          class="hero__cover__overlay"
          :style="{
            opacity: data.overlay_transparency,
            background: data.overlay_color
          }"
        ></div>
      </div>
      <div v-else-if="data.image">
        <div
          class="hero__image"
          :style="{ backgroundImage: url(data.image) }"
        ></div>
        <div
          class="hero__cover__overlay"
          :style="{
            opacity: data.overlay_transparency,
            background: data.overlay_color
          }"
        ></div>
      </div>
      <div class="hero__wrapper">
        <div class="hero__wrapper__subtitle" id="hero__wrapper__subtitle">
          {{ data.subtitle }}
        </div>
        <h1
          id="hero__wrapper__title"
          class="hero__wrapper__title"
          :data-text="data.title"
        >
          {{ data.title }}
        </h1>
        <div class="hero__wrapper__links">
          <div v-for="(single, index) in data.repeater_links_" :key="index">
            <router-link
              class="sword_link hero_video_selector"
              :data-video="single.gif"
              :to="single.link.link"
            >
              <span>{{ single.link.title }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeIt from "typeit";
import gsap from "gsap";

export default {
  name: "Hero",
  props: {
    data: Object
  },
  data(){
  	return{
  		hero_tl :0,
  		hero_video : 0,
  		ie: false
  	}
  },
  mounted(){
	this.animation_hero();
  },
  methods: {
  	animation_hero(){
		if ((typeof hero_tl === 'undefined') && (!this.ie)){
			this.hero_tl = gsap.timeline();

			let title = document.getElementById('hero__wrapper__title');
			let height = title.offsetHeight + 'px';
			title.style.height = height;

			new TypeIt("#hero__wrapper__subtitle", {
				speed: 25,
				loop: false,
				cursorSpeed: 450,
				cursorChar: '<i class="cursor--small cursor"></i>',
				afterComplete: async (step, instance) => {

				instance.destroy();
				gsap.to(['#hero__wrapper__title'], 0.1, {autoAlpha:1});

				new TypeIt("#hero__wrapper__title", {
					speed: 25,
					loop: false,
					cursorSpeed: 400,
					cursorChar: '<i class="cursor--large cursor"></i>',
					afterComplete: async (step, instance) => {
						gsap.to('.hero__wrapper__links a', 0.4, {autoAlpha:1, stagger:0.1, y:0});
						setTimeout(instance.destroy, 1700);
					} 
				}).go();

				} 
			}).go();


			this.hero_tl
			.to(['.hero__wrapper', '#hero__wrapper__subtitle'], 0.1, {autoAlpha:1});

			this.hero_tl.play();
			document.body.classList.add('hero_js');
			
		}else{
			gsap.to(['.hero__wrapper', '#hero__wrapper__title', '#hero__wrapper__subtitle', '.hero__wrapper__links a'], {autoAlpha:1, y:0});
		}

		this.hero_video.play();
  	},
	heroVideo() {
		this.hero_video = document.getElementById('hero__video');
		let heroVideoMainSrc = document.getElementById('hero__video__main__src');

	    let currentVideo = 1;
	    let obj = document.getElementById('video_obj').innerHTML;
	    obj = JSON.parse(obj);
		this.hero_video.play();

		this.hero_video.onended = function() {

				if(currentVideo == obj.length){
					currentVideo = 0;
				}
				heroVideoMainSrc.src = obj[currentVideo].video;
				this.hero_video.load();
				this.hero_video.play();
				currentVideo++;

		};

	}
  },
  computed: {}
};
</script>
