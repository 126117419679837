<template>
  <div
    class="quote"
    :id="id"
    :style="{ backgroundColor: data.background_color }"
  >
    <div class="container">
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-8">
          <h4 class="quote__text" :style="{ color: data.text_color }">
            {{ data.quote }}
          </h4>
          <span class="quote__author" :style="{ color: data.text_color }">{{
            data.author
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import uniqueId from "lodash-es/uniqueId";
export default {
  props: {
    data: Object
  },
  computed: {
    id() {
      return uniqueId("id");
    }
  }
};
</script>
