<template>
  <div class="default">
    <div v-for="(item, index) in content" :key="index">
      <div v-if="item.blockName === 'acf/hero'">
        <Hero :data="item.attrs.data"></Hero>
      </div>
      <div v-else-if="item.blockName === 'acf/case-text'">
        <CaseText :data="item.attrs.data"></CaseText>
      </div>
      <div v-else-if="item.blockName === 'acf/statistic'">
        <Statistic :data="item.attrs.data"></Statistic>
      </div>
      <div v-else-if="item.blockName === 'acf/video'">
        <Video :data="item.attrs.data"></Video>
      </div>
      <div v-else-if="item.blockName === 'acf/quote'">
        <Quote :data="item.attrs.data"></Quote>
      </div>
      <div v-else-if="item.blockName === 'acf/image'">
        <ImageBlock :data="item.attrs.data"></ImageBlock>
      </div>
      <div v-else-if="item.blockName === 'acf/latest-news'">
        <LatestNews :data="item.attrs.data"></LatestNews>
      </div>
      <div v-else-if="item.blockName === 'acf/carousel'">
        <Carousel :data="item.attrs.data"></Carousel>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import { setupCache } from "axios-cache-adapter";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import content from "@/homepage.json";
import Hero from "@/components/hero.vue";
import CaseText from "@/components/case-text.vue";
import Statistic from "@/components/statistic.vue";
import Video from "@/components/video.vue";
import Quote from "@/components/quote.vue";
import ImageBlock from "@/components/image.vue";
import LatestNews from "@/components/latest-news.vue";
import Carousel from "@/components/carousel.vue";

// const cache = setupCache({
//   maxAge: 15 * 60 * 1000
// });
// const api = axios.create({
//   adapter: cache.adapter
// });

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    Hero,
    CaseText,
    Statistic,
    Video,
    Quote,
    ImageBlock,
    LatestNews,
    Carousel
  },
  data: function() {
    return {
      content: content[0].content
    };
  },
  methods: {
    lazy() {
      ScrollTrigger.config({ limitCallbacks: true });

      gsap.utils.toArray(".lazyload").forEach(image => {
        let newSRC = image.dataset.src,
          newImage = document.createElement("img"),
          loadImage = () => {
            newImage.onload = () => {
              newImage.onload = null;
              newImage.src = image.src;
              image.src = newSRC;
              gsap.set(newImage, {
                position: "absolute",
                top: image.offsetTop,
                left: image.offsetLeft,
                width: image.offsetWidth,
                height: image.offsetHeight
              });
              image.parentNode.appendChild(newImage);
              gsap.to(newImage, {
                opacity: 0,
                onComplete: () => newImage.parentNode.removeChild(newImage)
              });
              st && st.kill();
            };
            newImage.src = newSRC;
          },
          st = ScrollTrigger.create({
            trigger: image,
            start: "-50% bottom",
            onEnter: loadImage,
            onEnterBack: loadImage
          });
      });
    }
  },
  created() {
  	console.log(content);
    // let slug =
    //   this.$route.fullPath === "/" ? "homepage" : this.$route.params.pageSlug;
    // api({
    //   // url: './json/homepage.json',
    //   url: "https://vue.rocoding.com/wp/wp-json/data/custom/" + slug,
    //   // url: '/json/homepage.json',
    //   method: "get"
    // }).then(async response => {
    //   console.log("Request response:", response.data);
    //   this.content = response.data[0].content;
    //   const length = await cache.store.length();
    //   console.log("Cache store length:", length);
      this.lazy();
    // });
  },
  mounted() {}
};
</script>
