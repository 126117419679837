<template>
  <div
    class="carouselWrapper"
    :id="id"
    :style="{ backgroundColor: data.background_color }"
  >
    <img
      src="https://st.rocoding.com/wp-content/uploads/2020/09/gradient-bg-42x24.jpg"
      data-src="https://st.rocoding.com/wp-content/uploads/2020/09/gradient-bg.jpg"
      alt="STEP cases"
      class="lazyload carouselWrapper__bg"
    />
    <div class="carousel siema" id="carouselWrap">
      <a
        v-for="(item, index) in data.repeater_items_"
        :key="index"
        class="carousel__slide"
        href="#"
      >
        <div class="carousel__slide__label">
          <span>Case</span>
          <span class="carousel__slide__label__name"
            >/&nbsp;&nbsp;item.select_page.title</span
          >
        </div>
        <h3 class="carousel__slide__h3">
          Fra redningsaktion i krisetid til nyt fokusområde i vækstplanen
        </h3>
      </a>
      <!-- 			<?php if(get_field('items')): ?>
				<?php while(have_rows('items')):the_row();

					$item = get_sub_field('select_page');
		    		$label = get_field('label', $item->ID);
					if(get_post_type($item->ID) == 'cases'){
						$firstLabel = 'Case';
						$secondLabel = $label;
					}else{
						$firstLabel = $label;
						$secondLabel = get_the_date('d.m.y');
					}
					?>
				<?php endwhile; ?>
			<?php endif; ?> -->
    </div>
  </div>
</template>

<script>
import uniqueId from "lodash-es/uniqueId";
import Siema from "siema";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "Carousel",
  data: function() {
    return {
      // timer: 0
    };
  },
  props: {
    data: Object
  },
  methods: {
    carousel_fn() {
      this.carousel_init();
      // if(typeof ScrollTrigger.getById('carousel') !== 'undefined'){
      // 	ScrollTrigger.getById('carousel').kill();
      // }

      // ScrollTrigger.create({
      // 	id: 'carousel',
      // 	trigger: ".carouselWrapper",
      // 	start: "-20% bottom",
      // 	onEnter: self => {
      // 		if(document.getElementsByClassName('siema')[0])
      // 			if(!document.getElementsByClassName('siema')[0].classList.contains('carousel--active')){
      // 				clearInterval(timer);
      // 				carousel_init();
      // 			}
      // 	}
      // });
    },
    carousel_init() {
      let paused = false;
      // let carousel_tl = gsap.timeline();
      let el = document.getElementById("carouselWrap");
      let another = el.cloneNode(true);

      el.innerHTML += another.innerHTML + another.innerHTML;

      let perPage = 1;
      let width = window.innerWidth;
      if (width > 1800) {
        perPage = 4;
      } else if (width > 1410) {
        perPage = 3;
      } else if (width > 992) {
        perPage = 1;
      }

      let carousel = new Siema({
        selector: ".siema",
        duration: 4000,
        easing: "linear",
        perPage: perPage,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        rtl: false
      });

      carousel.next();

      // let timer = setInterval(function(){
      setInterval(function() {
        if (!paused) {
          carousel.next();
        }
      }, 3990);

      document
        .getElementsByClassName("siema")[0]
        .classList.add("carousel--active");

      // $('.siema').on('mouseover', function(){
      // 	paused = true;
      // });

      // $('.siema').on('mouseleave', function(){
      // 	paused = false;
      // });
    }
  },
  mounted() {
    this.carousel_fn();
  },
  computed: {
    id() {
      return uniqueId("id");
    }
  }
};
</script>
