<template>
  <div class="videoBlock" :style="{ backgroundColor: data.background_color }">
    <div class="container">
      <div class="row">
        <div class="col-12 offset-lg-1 col-lg-10">
          <div class="videoBlock__embed">
            <iframe
              :src="generateVideoUrl(data)"
              width="640"
              height="360"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  methods: {
    generateVideoUrl(videoObj) {
      let videoUrl;
      videoUrl =
        "https://player.vimeo.com/video/" +
        videoObj.vimeo_video_id +
        "/?autopause=0";
      videoUrl += videoObj.muted == 1 ? "&muted=1" : "";
      videoUrl += videoObj.autoplay == 1 ? "&autoplay=1" : "";
      videoUrl += videoObj.loop_video == 1 ? "&loop=1" : "";
      videoUrl += videoObj.allow_fullscreen == 1 ? "&fullscreen=1" : "";
      videoUrl += videoObj.background == 1 ? "&background=1" : "";
      return videoUrl;
    }
  }
};
</script>
