<template>
  <div
    :class="imageClass(data)"
    :style="{ backgroundColor: data.background_color }"
  >
    <div v-if="data.size === 'full'">
      <div v-if="data.video" class="imageBlock__full videoBlockGif imageAspect">
        <video id="hero__video" type="video/mp4" autoplay muted>
          <source
            :src="data.video"
            id="hero__video__main__src"
            type="video/mp4"
          />
        </video>
        <div
          class="hero__cover__overlay"
          :style="{
            background: data.overlay_color,
            opacity: data.overlay_transparency
          }"
        ></div>
        <p>{{ data.text }}</p>
      </div>
      <!-- <script id="video_obj" type="application/json"><?php echo json_encode(get_field('video')); ?></script> -->
      <div v-else class="imageBlock__full imageAspect">
        <img
          :src="data.image['post-temp']"
          :data-src="data.image.large"
          class="lazyload"
          :alt="data.alt_tag"
        />
        <p>{{ data.text }}</p>
      </div>
    </div>
    <div v-else-if="data.size == 'container'">
      <div class="container">
        <div class="row">
          <div class="col-12 offset-lg-1 col-lg-10">
            <div
              v-if="data.video"
              class="imageBlock__container videoBlockGif imageAspect"
            >
              <video id="hero__video" type="video/mp4" autoplay muted>
                <source
                  :src="data.video"
                  id="hero__video__main__src"
                  type="video/mp4"
                />
              </video>
              <div
                class="hero__cover__overlay"
                :style="{
                  background: data.overlay_color,
                  opacity: data.overlay_transparency
                }"
              ></div>
              <p>{{ data.text }}</p>
            </div>
            <div v-else class="imageBlock__container imageAspect">
              <img
                :src="data.image['post-temp']"
                :data-src="data.image.full"
                class="lazyload"
                :alt="data.alt_tag"
              />
              <p>{{ data.text }}</p>
            </div>
            <!-- <script id="video_obj" type="application/json"><?php echo json_encode(get_field('video')); ?></script> -->
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="row">
          <div class="col-12 offset-lg-1 col-lg-10">
            <div class="imageBlock__actual">
              <img
                :src="data.image['post-temp']"
                :data-src="data.image.full"
                class="lazyload"
                alt="data.image.alt_tag"
              />
              <p>{{ data.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // imageClass : 'imageBlock '
    };
  },
  props: {
    data: Object
  },
  methods: {
    imageClass(data) {
      let className = "imageBlock";
      className += data.small_margin_top == 1 ? " smallTop" : "";
      className += data.small_margin_bottom == 1 ? " smallBottom" : "";
      className += data.no_margin_top == 1 ? " noTop" : "";
      className += data.no_margin_bottom == 1 ? " noBottom" : "";
      className += " aspect__" + data.aspect_ratio;
      return className;
    }
  },
  rendered() {
    this.imageClass = "test";
  }
};
</script>
