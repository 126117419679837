<template>
  <div class="statistic" :style="{ backgroundColor: data.background_color }">
    <div v-for="(item, index) in data.repeater_stat_" :key="index">
      <div class="statistic__single">
        <div class="statistic__single__name">{{ item.abbreviation }}</div>
        <div class="statistic__single__number">
          <span>{{ item.sign_before }}</span>
          <div class="stat-js-wrapper">
            <div v-for="(number, i) in getCharsArray(item.number)" :key="i">
              <div class="stat-js stat-js-prototype" :data-stat="number">
                <div class="stat__inner">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                </div>
              </div>
            </div>
          </div>
          <span>{{ item.sign_after }}</span>
        </div>
        <span class="statistic__single__desc">{{ item.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
export default {
  props: {
    data: Object
  },
  methods: {
    getCharsArray(stat) {
      let list = [];
      for (var i = 0; i < stat.length; i++) {
        list.push(stat.charAt(i));
      }
      return list;
    }
  },
  mounted() {
    gsap.utils.toArray(".stat-js-prototype").forEach(element => {
      let num = parseInt(element.getAttribute("data-stat")) * -100;
      let inner = element.getElementsByClassName("stat__inner")[0];

      gsap.to(inner, {
        duration: 3,
        ease: "power2.out",
        y: num + "%"
        // scrollTrigger: {
        // 	id: 'stats',
        // 	trigger: element,
        // 	start: "bottom bottom",
        // 	end: "bottom",
        // 	toggleActions: "play none none none"
        //       },
      });
    });
  }
};
</script>
